<template>
  <c-flex
    flex-dir="column"
    w="100%"
  >
    <Breadcrumb :items="breadcrumbs" />
    <hr
      v-chakra
      w="100%"
    >
    <c-flex mt="20px">
      <c-text
        flex="1"
        font-size="3xl"
      >
        Manajemen Klien
      </c-text>
      <c-button
        v-if="isRoleSuperAdmin"
        type="button"
        variant="solid"
        variant-color="gray"
        color="primary.900"
        rounded="full"
        as="a"
        :href="exportUrl"
        :download="`${fullName}.pdf`"
      >
        <c-image :src="require('@/assets/icon-file-export.svg')" />
        <c-text
          ml="5px"
          font-weight="500"
          font-size="16px"
        >
          Export
        </c-text>
      </c-button>
      <c-button
        v-if="isRoleSuperAdmin"
        type="button"
        variant="solid"
        variant-color="primary"
        color="white"
        rounded="full"
        ml="10px"
        as="router-link"
        :to="{
          name: 'superadmin.clients-edit',
          params: { clientId: clientId },
        }"
      >
        <c-image :src="require('@/assets/icon-pencil.svg')" />
        <c-text
          ml="5px"
          font-weight="500"
          font-size="16px"
        >
          Edit
        </c-text>
      </c-button>
    </c-flex>
    <c-flex
      v-if="client"
      mt="30px"
      flex-dir="column"
    >
      <c-text font-size="xl">
        Data Pribadi
      </c-text>
      <hr
        v-chakra
        w="100%"
        mt="10px"
      >
      <c-flex mt="20px">
        <c-image
          :src="avatarUrl"
          h="156px"
          w="156px"
          object-fit="cover"
          rounded="full"
        />
        <c-flex
          ml="20px"
          flex-dir="column"
        >
          <c-box>
            <c-text
              font-size="16px"
              font-weight="500"
              color="primary.400"
            >
              Nama
            </c-text>
            <c-flex>
              <c-text
                font-size="24px"
                font-weight="700"
              >
                {{ fullName }}
              </c-text>
              <c-text
                v-if="client.status === 'active'"
                color="#0075E1"
                bg="#0075E11A"
                border="1px solid #0075E1"
                rounded="full"
                w="fit-content"
                h="fit-content"
                px="10px"
                py="3px"
                ml="10px"
              >
                Aktif
              </c-text>
              <c-text
                v-if="client.status === 'pending'"
                bg="rgba(244, 204, 70, 0.2)"
                border="1px solid #F4CC46"
                color="#F4CC46"
                rounded="full"
                w="fit-content"
                h="fit-content"
                px="10px"
                py="3px"
                ml="10px"
              >
                Pending
              </c-text>
              <c-text
                v-if="client.status === 'done' || client.status === 'non_active'"
                bg="rgba(136, 136, 136, 0.2)"
                border="1px solid #888888"
                color="#888888"
                rounded="full"
                w="fit-content"
                h="fit-content"
                px="10px"
                py="3px"
                ml="10px"
              >
                Tidak Aktif
              </c-text>
            </c-flex>
          </c-box>
          <c-box mt="10px">
            <c-text
              font-size="16px"
              font-weight="500"
              color="primary.400"
            >
              Nomor Handphone
            </c-text>
            <c-text
              font-size="18px"
              font-weight="400"
            >
              {{
                client.phone
              }}
            </c-text>
          </c-box>
          <c-box mt="10px">
            <c-text
              font-size="16px"
              font-weight="500"
              color="primary.400"
            >
              Email
            </c-text>
            <c-text
              font-size="18px"
              font-weight="400"
            >
              {{
                client.email
              }}
            </c-text>
          </c-box>
        </c-flex>
      </c-flex>
    </c-flex>
    <c-flex
      v-if="programs"
      mt="30px"
      flex-dir="column"
    >
      <c-text font-size="xl">
        Riwayat Program
      </c-text>
      <hr
        v-chakra
        w="100%"
      >
      <c-flex
        mt="20px"
        grid-gap="20px"
        wrap="wrap"
      >
        <router-link
          v-for="(item, index) in programs"
          :key="index"
          as="router-link"
          :to="{
            name: 'superadmin.clients-programs',
            params: { programId: item.id },
          }"
        >
          <c-flex
            flex-dir="column"
            border="1px solid #F2F2F2"
            box-shadow="0px 5px 30px 0px #0000000D"
            p="20px"
            w="435px"
            h="132px"
            rounded="lg"
          >
            <c-flex
              w="100%"
              align-items="center"
            >
              <c-text
                flex="1"
                font-size="xl"
                font-weight="500"
              >
                {{
                  item.name
                }}
              </c-text>
              <c-text
                v-if="item.status === 'active'"
                color="#0075E1"
                bg="#0075E11A"
                border="1px solid #0075E1"
                rounded="full"
                w="fit-content"
                px="10px"
                py="3px"
              >
                Aktif
              </c-text>
              <c-text
                v-if="item.status === 'pending'"
                bg="rgba(244, 204, 70, 0.2)"
                border="1px solid #F4CC46"
                color="#F4CC46"
                rounded="full"
                w="fit-content"
                px="10px"
                py="3px"
              >
                Pending
              </c-text>
              <c-text
                v-if="item.status === 'done' || item.status === 'non_active'"
                bg="rgba(136, 136, 136, 0.2)"
                border="1px solid #888888"
                color="#888888"
                rounded="full"
                w="fit-content"
                px="10px"
                py="3px"
              >
                Tidak Aktif
              </c-text>
            </c-flex>
            <c-box
              v-if="item.endAt"
              mt="10px"
            >
              <c-text
                font-size="md"
                color="primary.400"
              >
                Berakhir pada
              </c-text>
              <c-text font-size="md">
                {{
                  item.endAt | formatDate("DD MMMM YYYY")
                }}
              </c-text>
            </c-box>
          </c-flex>
        </router-link>
      </c-flex>
    </c-flex>
    <c-flex
      v-if="orders"
      mt="30px"
      flex-dir="column"
    >
      <c-text font-size="xl">
        Riwayat Pembayaran
      </c-text>
      <hr
        v-chakra
        w="100%"
      >
      <c-flex
        mt="20px"
        grid-gap="20px"
        wrap="wrap"
      >
        <c-flex
          v-for="(item, index) in orders"
          :key="index"
          flex-dir="column"
          border="1px solid #F2F2F2"
          box-shadow="0px 5px 30px 0px #0000000D"
          p="20px"
          w="435px"
          h="132px"
          rounded="lg"
        >
          <c-flex
            w="100%"
            align-items="center"
          >
            <c-text
              flex="1"
              font-size="xl"
              font-weight="500"
            >
              {{
                item.invoiceNumber
              }}
            </c-text>
          </c-flex>
          <c-box mt="10px">
            <c-text font-size="md">
              Dibayarkan pada ...
            </c-text>
          </c-box>
          <c-box mt="5px">
            <c-text
              v-if="item.status === 'done' || item.status === 'non_active'"
              font-size="md"
              color="primary.400"
            >
              Tidak Aktif
            </c-text>
            <c-text
              v-else
              font-size="md"
              color="red.900"
            >
              Belum selesai
            </c-text>
          </c-box>
        </c-flex>
      </c-flex>
    </c-flex>
    <c-flex
      v-if="coupons"
      mt="30px"
      flex-dir="column"
    >
      <c-text font-size="xl">
        Riwayat Penggunaan Kupon
      </c-text>
      <hr
        v-chakra
        w="100%"
      >
      <c-flex
        mt="20px"
        grid-gap="20px"
        wrap="wrap"
      >
        <c-flex
          v-for="(item, index) in coupons"
          :key="index"
          flex-dir="column"
          border="1px solid #F2F2F2"
          box-shadow="0px 5px 30px 0px #0000000D"
          p="20px"
          w="435px"
          h="132px"
          rounded="lg"
        >
          <c-flex
            w="100%"
            align-items="center"
          >
            <c-text
              flex="1"
              font-size="xl"
              font-weight="500"
            >
              {{
                item.name
              }}
            </c-text>
          </c-flex>
          <c-box mt="10px">
            <c-text font-size="md">
              Digunakan pada {{ item.usedAt | formatDate("DD MMMM YYYY") }}
            </c-text>
          </c-box>
          <c-box mt="5px">
            <c-text
              font-size="md"
              color="primary.400"
            >
              – Rp {{ Number(item.discountValue).toLocaleString("id") }}
            </c-text>
          </c-box>
        </c-flex>
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb'
export default {
  name: 'SUClientDetail',
  components: { Breadcrumb },
  data() {
    return {
      breadcrumbs: [
        {
          label: 'Manajemen Klien',
          href: '/management/clients',
          isCurrent: false,
        },
        {
          label: 'Detail',
          // , href: this.$route.path
          isCurrent: true,
        },
      ],
    }
  },
  computed: {
    client() {
      return this.$store.getters['suManagementClient/clientById'](this.clientId)
    },
    clientId() {
      return this.$route.params.clientId
    },
    avatarUrl() {
      return this.client?.photoUrl ?? 'https://ik.imagekit.io/dietela/pwa_webp/profile/profile_neutral.webp'
    },
    coupons() {
      return this.client?.coupons
    },
    programs() {
      return this.client?.programs
    },
    orders() {
      return this.client?.orders
    },
    exportUrl() {
      let baseUrl = this.$store.getters.axios.defaults.baseURL
      let token = this.$store.state.auth.token
      let params = new URLSearchParams()
      params.set('token', token)

      return `${baseUrl}/v1/super-admin/clients/${
        this.clientId
      }/export?${params.toString()}`
    },
    fullName() {
      return `${this.client?.firstName} ${this.client?.lastName}`
    },
  },
  watch: {
    clientId: {
      immediate: true,
      handler(clientId) {
        this.$store.dispatch('suManagementClient/getClientById', clientId)
        this.$store.dispatch('suFollowUpNotes/getFollowUpNotes', clientId)
      },
    },
  },
}
</script>
