var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "w": "100%"
    }
  }, [_c('Breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbs
    }
  }), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "100%"
    }
  }), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-size": "3xl"
    }
  }, [_vm._v(" Manajemen Klien ")]), _vm.isRoleSuperAdmin ? _c('c-button', {
    attrs: {
      "type": "button",
      "variant": "solid",
      "variant-color": "gray",
      "color": "primary.900",
      "rounded": "full",
      "as": "a",
      "href": _vm.exportUrl,
      "download": "".concat(_vm.fullName, ".pdf")
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-file-export.svg')
    }
  }), _c('c-text', {
    attrs: {
      "ml": "5px",
      "font-weight": "500",
      "font-size": "16px"
    }
  }, [_vm._v(" Export ")])], 1) : _vm._e(), _vm.isRoleSuperAdmin ? _c('c-button', {
    attrs: {
      "type": "button",
      "variant": "solid",
      "variant-color": "primary",
      "color": "white",
      "rounded": "full",
      "ml": "10px",
      "as": "router-link",
      "to": {
        name: 'superadmin.clients-edit',
        params: {
          clientId: _vm.clientId
        }
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-pencil.svg')
    }
  }), _c('c-text', {
    attrs: {
      "ml": "5px",
      "font-weight": "500",
      "font-size": "16px"
    }
  }, [_vm._v(" Edit ")])], 1) : _vm._e()], 1), _vm.client ? _c('c-flex', {
    attrs: {
      "mt": "30px",
      "flex-dir": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "xl"
    }
  }, [_vm._v(" Data Pribadi ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "100%",
      "mt": "10px"
    }
  }), _c('c-flex', {
    attrs: {
      "mt": "20px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.avatarUrl,
      "h": "156px",
      "w": "156px",
      "object-fit": "cover",
      "rounded": "full"
    }
  }), _c('c-flex', {
    attrs: {
      "ml": "20px",
      "flex-dir": "column"
    }
  }, [_c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "500",
      "color": "primary.400"
    }
  }, [_vm._v(" Nama ")]), _c('c-flex', [_c('c-text', {
    attrs: {
      "font-size": "24px",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.fullName) + " ")]), _vm.client.status === 'active' ? _c('c-text', {
    attrs: {
      "color": "#0075E1",
      "bg": "#0075E11A",
      "border": "1px solid #0075E1",
      "rounded": "full",
      "w": "fit-content",
      "h": "fit-content",
      "px": "10px",
      "py": "3px",
      "ml": "10px"
    }
  }, [_vm._v(" Aktif ")]) : _vm._e(), _vm.client.status === 'pending' ? _c('c-text', {
    attrs: {
      "bg": "rgba(244, 204, 70, 0.2)",
      "border": "1px solid #F4CC46",
      "color": "#F4CC46",
      "rounded": "full",
      "w": "fit-content",
      "h": "fit-content",
      "px": "10px",
      "py": "3px",
      "ml": "10px"
    }
  }, [_vm._v(" Pending ")]) : _vm._e(), _vm.client.status === 'done' || _vm.client.status === 'non_active' ? _c('c-text', {
    attrs: {
      "bg": "rgba(136, 136, 136, 0.2)",
      "border": "1px solid #888888",
      "color": "#888888",
      "rounded": "full",
      "w": "fit-content",
      "h": "fit-content",
      "px": "10px",
      "py": "3px",
      "ml": "10px"
    }
  }, [_vm._v(" Tidak Aktif ")]) : _vm._e()], 1)], 1), _c('c-box', {
    attrs: {
      "mt": "10px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "500",
      "color": "primary.400"
    }
  }, [_vm._v(" Nomor Handphone ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.client.phone) + " ")])], 1), _c('c-box', {
    attrs: {
      "mt": "10px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "500",
      "color": "primary.400"
    }
  }, [_vm._v(" Email ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.client.email) + " ")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.programs ? _c('c-flex', {
    attrs: {
      "mt": "30px",
      "flex-dir": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "xl"
    }
  }, [_vm._v(" Riwayat Program ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "100%"
    }
  }), _c('c-flex', {
    attrs: {
      "mt": "20px",
      "grid-gap": "20px",
      "wrap": "wrap"
    }
  }, _vm._l(_vm.programs, function (item, index) {
    return _c('router-link', {
      key: index,
      attrs: {
        "as": "router-link",
        "to": {
          name: 'superadmin.clients-programs',
          params: {
            programId: item.id
          }
        }
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-dir": "column",
        "border": "1px solid #F2F2F2",
        "box-shadow": "0px 5px 30px 0px #0000000D",
        "p": "20px",
        "w": "435px",
        "h": "132px",
        "rounded": "lg"
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "align-items": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "flex": "1",
        "font-size": "xl",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), item.status === 'active' ? _c('c-text', {
      attrs: {
        "color": "#0075E1",
        "bg": "#0075E11A",
        "border": "1px solid #0075E1",
        "rounded": "full",
        "w": "fit-content",
        "px": "10px",
        "py": "3px"
      }
    }, [_vm._v(" Aktif ")]) : _vm._e(), item.status === 'pending' ? _c('c-text', {
      attrs: {
        "bg": "rgba(244, 204, 70, 0.2)",
        "border": "1px solid #F4CC46",
        "color": "#F4CC46",
        "rounded": "full",
        "w": "fit-content",
        "px": "10px",
        "py": "3px"
      }
    }, [_vm._v(" Pending ")]) : _vm._e(), item.status === 'done' || item.status === 'non_active' ? _c('c-text', {
      attrs: {
        "bg": "rgba(136, 136, 136, 0.2)",
        "border": "1px solid #888888",
        "color": "#888888",
        "rounded": "full",
        "w": "fit-content",
        "px": "10px",
        "py": "3px"
      }
    }, [_vm._v(" Tidak Aktif ")]) : _vm._e()], 1), item.endAt ? _c('c-box', {
      attrs: {
        "mt": "10px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "md",
        "color": "primary.400"
      }
    }, [_vm._v(" Berakhir pada ")]), _c('c-text', {
      attrs: {
        "font-size": "md"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.endAt, "DD MMMM YYYY")) + " ")])], 1) : _vm._e()], 1)], 1);
  }), 1)], 1) : _vm._e(), _vm.orders ? _c('c-flex', {
    attrs: {
      "mt": "30px",
      "flex-dir": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "xl"
    }
  }, [_vm._v(" Riwayat Pembayaran ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "100%"
    }
  }), _c('c-flex', {
    attrs: {
      "mt": "20px",
      "grid-gap": "20px",
      "wrap": "wrap"
    }
  }, _vm._l(_vm.orders, function (item, index) {
    return _c('c-flex', {
      key: index,
      attrs: {
        "flex-dir": "column",
        "border": "1px solid #F2F2F2",
        "box-shadow": "0px 5px 30px 0px #0000000D",
        "p": "20px",
        "w": "435px",
        "h": "132px",
        "rounded": "lg"
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "align-items": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "flex": "1",
        "font-size": "xl",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(item.invoiceNumber) + " ")])], 1), _c('c-box', {
      attrs: {
        "mt": "10px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "md"
      }
    }, [_vm._v(" Dibayarkan pada ... ")])], 1), _c('c-box', {
      attrs: {
        "mt": "5px"
      }
    }, [item.status === 'done' || item.status === 'non_active' ? _c('c-text', {
      attrs: {
        "font-size": "md",
        "color": "primary.400"
      }
    }, [_vm._v(" Tidak Aktif ")]) : _c('c-text', {
      attrs: {
        "font-size": "md",
        "color": "red.900"
      }
    }, [_vm._v(" Belum selesai ")])], 1)], 1);
  }), 1)], 1) : _vm._e(), _vm.coupons ? _c('c-flex', {
    attrs: {
      "mt": "30px",
      "flex-dir": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "xl"
    }
  }, [_vm._v(" Riwayat Penggunaan Kupon ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "100%"
    }
  }), _c('c-flex', {
    attrs: {
      "mt": "20px",
      "grid-gap": "20px",
      "wrap": "wrap"
    }
  }, _vm._l(_vm.coupons, function (item, index) {
    return _c('c-flex', {
      key: index,
      attrs: {
        "flex-dir": "column",
        "border": "1px solid #F2F2F2",
        "box-shadow": "0px 5px 30px 0px #0000000D",
        "p": "20px",
        "w": "435px",
        "h": "132px",
        "rounded": "lg"
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "align-items": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "flex": "1",
        "font-size": "xl",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1), _c('c-box', {
      attrs: {
        "mt": "10px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "md"
      }
    }, [_vm._v(" Digunakan pada " + _vm._s(_vm._f("formatDate")(item.usedAt, "DD MMMM YYYY")) + " ")])], 1), _c('c-box', {
      attrs: {
        "mt": "5px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "md",
        "color": "primary.400"
      }
    }, [_vm._v(" – Rp " + _vm._s(Number(item.discountValue).toLocaleString("id")) + " ")])], 1)], 1);
  }), 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }